<template>
  <div class="training-manage">
    <table-list
      title="证书培训管理"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="dataList"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :options="{ selection: true, operatesWidth: 170 }"
      :pager="pager"
      :tabs-list="tabsList"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <ShowDia ref="ShowDia" @success="getList" />
  </div>
</template>
<script>
//表格tabs
const tabsList = [
  {
    prop: 'publishStatus',
    activeValue: '',
    children: [
      { value: 1, label: '编辑中' },
      { value: 2, label: '进行中' },
      { value: 4, label: '已完成' },
    ],
  },
  {
    prop: 'productAttributeCategory',
    activeValue: '',
    children: [
      { value: 3, label: '职业等级证书' },
      { value: 2, label: '专项职业能力证书' },
    ],
  },
]
// 顶部按钮
const btns = _this => [
  {
    label: '创建证书培训',
    method: _this.handleAdd,
  },
  {
    label: '导出',
    method: _this.handleExport,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '证书培训计划名称',
    prop: 'className',
  },
  {
    label: '适用证书',
    type: 'select',
    prop: 'certId',
    children: _this.certList.map(item => ({
      value: item.certId,
      label: item.certName,
    })),
  },
  {
    label: '考试时间',
    type: 'picker',
    prop: 'examTime',
    pickerDate: _this.pickerDate,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'className',
    minWidth: 150,
    label: '证书培训名称',
    twoLines: true,
  },
  {
    prop: 'certName',
    minWidth: 150,
    label: '适用证书',
    twoLines: true,
  },
  {
    prop: 'grade',
    minWidth: 76,
    label: '适用等级',
  },
  {
    prop: 'studentNum',
    minWidth: 76,
    label: '人数',
  },
  {
    prop: 'examTime',
    label: '考试时间',
    minWidth: 150,
    sortdata: 1,
  },
  {
    prop: 'publishStatusName',
    label: '状态',
    minWidth: 62,
  },
  {
    prop: 'classCreateUserName',
    minWidth: 76,
    label: '发布人',
  },
  {
    prop: 'classCreateTime',
    label: '创建时间',
    minWidth: 150,
    sortdata: 2,
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '设置展示',
    method: _this.handleShow,
    isShow: item => item.showFlag == 1 && item.publishStatus == 2,
  },
  {
    label: '已设置',
    method: _this.handleShow,
    isShow: item => item.showFlag == 2 && item.publishStatus == 2,
  },
  {
    label: '编辑',
    method: _this.handleEdit,
    // isShow: item => item.status == 0 || item.status == 3,
  },
  {
    label: '公告',
    method: _this.handleNotice,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    isShow: item => item.publishStatus == 1,
  },
]
import TableList from '@/components/TableList'
import ShowDia from './components/ShowDia'
import {
  classCertQuery,
  classCertExport,
  classCertDelete,
  classCertCreate,
  certOrgQuery,
} from '@/api/certificate'
import to from 'await-to'
export default {
  name: 'TrainingManage',
  components: {
    TableList,
    ShowDia,
  },
  data() {
    return {
      pickerDate: {
        onPick: ({ minDate }) => {
          this.pickerMinDate = minDate.getTime()
        },
        disabledDate: time => {
          const year = 365 * 24 * 3600 * 1000
          let oneYearLater = this.pickerMinDate + year
          return time.getTime() > oneYearLater //注意是||不是&&
        },
      },
      btns,
      tabsList,
      searchForm,
      columns,
      operates,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      certList: [],
      dataList: [],
      selectList: [],
    }
  },
  mounted() {
    this.getCertList()
    this.getList()
  },
  methods: {
    async getCertList() {
      const [res, err] = await to(certOrgQuery({}))
      if (err) return this.$message.warning(err.msg)
      this.certList = res.data
    },
    async getList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(classCertQuery({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total
      this.dataList = res.data.list
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      if (queryParams.examTime && queryParams.examTime !== 0) {
        this.queryParams.start = queryParams.examTime[0]
        this.queryParams.end = queryParams.examTime[1].split(' ')[0] + ' 23:59:59'
      } else {
        this.queryParams.start = ''
        this.queryParams.end = ''
      }
      this.getList()
    },
    async handleAdd() {
      const [res, err] = await to(classCertCreate({}))
      if (err) return this.$message.warning(err.msg)
      this.$router.push(`/certificate/trainingManage/${res.data}`)
    },
    handleShow(row) {
      this.$refs.ShowDia.show(row.classCertId)
    },
    handleEdit(row) {
      this.$router.push(`/certificate/trainingManage/${row.classCertId}`)
    },
    handleDelete({ classCertId }) {
      this.$confirm('您确定要删除吗？删除后将不可恢复', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(classCertDelete({ classCertId }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getList()
      })
    },
    async handleExport() {
      let ids = []
      this.selectList.map(v => ids.push(v.classCertId))
      const { current, size } = this.pager
      const [res, err] = await to(classCertExport({ current, size, ...this.queryParams, ids }))
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '证书培训管理导出.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    handleNotice({ classCertId }) {
      this.$router.push(`/certificate/trainingManage/notice?classCertId=${classCertId}`)
    },
    // 多选
    handleSelectionChange(val) {
      this.selectList = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getList()
    },
  },
}
</script>
