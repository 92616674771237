<template>
  <el-dialog :close-on-click-modal="false" class="add-principal" :visible.sync="dialogVisible">
    <div class="title">设置展示</div>
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>
    <div class="showType">
      <div class="showTitle">选择展示渠道</div>
      <el-checkbox-group v-model="checkList" class="checkList">
        <el-checkbox :label="1">证书网</el-checkbox>
        <el-checkbox :label="2">明职道</el-checkbox>
      </el-checkbox-group>
    </div>

    <div class="content">
      <div class="showTitle" style="padding-left:20px">选择跳转商品</div>
      <table-list
        ref="principalTable"
        class="table-list"
        :loading="loading"
        :data="list"
        :search-form="searchForm(this)"
        :columns="columns(this)"
        :pager="pager"
        :options="{
          singleCurrent: true,
          height: 400,
        }"
        :class="{ checked: selectPrincipal.length && selectPrincipal.length > 0 }"
        @search="onSearch"
        @singleSelectChange="handleSelectionChange"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></table-list>
      <div class="btnsWrap">
        <el-button type="primary" @click="cancelSetting">取消设置</el-button>
        <div class="btns">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submit">确定设置</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
const searchForm = _this => [
  {
    label: '证书商品ID',
    prop: 'productId',
    width: 120,
  },
  {
    label: '证书商品名称',
    prop: 'productName',
    width: 120,
  },
  {
    label: '证书类目',
    prop: 'productAttributeCategoryId',
    width: 120,
    type: 'select',
    children: _this.selectCert.map(v => ({
      value: v.productAttributeCategoryId,
      label: v.productAttributeCategoryName,
    })),
    onChange: e => _this.changeStatus(e),
  },
  {
    label: '证书',
    prop: 'certId',
    width: 120,
    type: 'select',
    children: _this.selectOrgCert.map(v => ({
      value: v.certId,
      label: v.certName,
    })),
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'productId',
    label: 'ID',
  },
  {
    prop: 'productName',
    label: '证书商品名称',
  },
  {
    prop: 'productAttributeCategoryName',
    label: '商品类目',
  },
  {
    prop: 'certName',
    label: '对应证书',
  },
]
import TableList from '@/components/TableList'
import {
  certProductList,
  selectCertList,
  selectOrgList,
  channelList,
  submitChannel,
} from '@/api/certificate'
import to from 'await-to'
export default {
  name: 'AddPrincipalDialog',
  components: { TableList },
  data() {
    return {
      searchForm,
      columns,
      dialogVisible: false,
      loading: false,
      list: [],
      selectPrincipal: [],
      queryParams: {},
      classCertId: '',
      checkList: [],
      selectCert: [],
      selectOrgCert: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
        count: 5,
      },
      pageSzie: 0,
      selectId: '',
    }
  },

  methods: {
    async show(classCertId) {
      this.dialogVisible = true
      this.classCertId = classCertId
      this.pager.current = 1
      await this.channelListData(classCertId)
      await this.certProductListData()
      this.selectCertListData()
      // this.$nextTick(() => {
      // this.$refs.principalTable.$refs.tableList.clearSelection()
      // if (this.selectId != null && this.selectId != '') {
      // this.$refs.principalTable.$refs.tableList.toggleRowSelection(this.list[0])
      // this.$refs.principalTable.$refs.tableList.setCurrentRow(this.list[0])
      // }
      // this.list.map(v => {
      //   if (v.productId == this.selectId) {
      //     this.$refs.principalTable.$refs.tableList.toggleRowSelection(v)
      //   }
      // })
      // })
    },
    async channelListData(id) {
      const [res, err] = await to(channelList({ classCertId: id }))
      if (err) return this.$message.warning(err.msg)
      this.checkList = res.data.displayClientIds
      this.selectId = res.data.productId
    },
    async certProductListData() {
      const { size, current } = this.pager
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(
        certProductList({ size, current, ...this.queryParams, classCertId: this.classCertId }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.list = res.data.list
      this.pager.total = res.data.total
      if (this.selectId != null && this.selectId != '' && this.pager.current == 1) {
        this.list.map(v => {
          if (v.displayProductFlag) {
            this.$refs.principalTable.$refs.tableList.setCurrentRow(this.list[0])
          }
        })
      }
    },
    async selectCertListData() {
      const [res, err] = await to(selectCertList({}))
      if (err) return this.$message.warning(err.msg)
      this.selectCert = res.data
    },
    async selectOrgListData(e) {
      const [res, err] = await to(selectOrgList({ productAttributeCategoryId: e }))
      if (err) return this.$message.warning(err.msg)
      this.selectOrgCert = res.data
    },
    changeStatus(e) {
      this.selectOrgListData(e)
    },
    submit() {
      this.set(2, false)
    },
    cancelSetting() {
      this.set(2, true)
    },
    async set(satus, boolean) {
      console.log(this.selectId)
      if (this.selectId == null || this.selectId == '')
        return this.$message.warning('请选择一个跳转商品')
      const [, err] = await to(
        submitChannel({
          classCertId: this.classCertId,
          displayClientIds: this.checkList,
          productId: this.selectId,
          unbind: boolean,
        }),
      )
      if (err) return this.$message.warning(err.msg)
      if (satus == 1) {
        this.$message.success('取消成功')
      } else {
        this.$message.success('设置成功')
      }
      this.$emit('success')
      this.dialogVisible = false
    },
    // 查询按钮
    onSearch(queryParams) {
      this.queryParams = queryParams
      this.certProductListData()
    },
    // 多选
    handleSelectionChange(val) {
      // this.selectPrincipal = val
      if (!val) return
      this.selectId = val.productId
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.certProductListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.certProductListData()
    },
  },
}
</script>
<style lang="scss" scoped>
.add-principal {
  ::v-deep.checked {
    .el-checkbox__input.is-checked .el-checkbox__inner {
      display: block !important;
    }
    .el-checkbox__input .el-checkbox__inner {
      // display: none;
      color: #aca899;
    }
  }
  ::v-deep.table-list {
    thead .el-checkbox__input .el-checkbox__inner {
      display: none;
    }
  }
  ::v-deep.el-dialog {
    position: relative;
    width: 660px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 14px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .title {
      height: 62px;
      line-height: 62px;
      border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .content {
      padding: 30px 10px;
      .btnsWrap {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
      }
      > .btns {
        text-align: right;
        padding-top: 30px;
      }
    }
  }
}
.showType {
  margin-top: 20px;
  .checkList {
    padding-left: 30px;
  }
}
.showTitle {
  padding-left: 30px;
  font-size: 18px;
  color: #333;
  margin-bottom: 15px;
}
</style>
